import { getExpansionPanelTheme } from 'Components/sq-graphics/config';
import {
  IAddonData,
  TGraphSettings,
  TAddonTheme,
  TExpansionPanelTheme,
  TAddonOption,
  IGraphDimensions,
} from 'Components/sq-graphics/interfaces';
import * as React from 'react';
import colors from '../../../../config/theme/colors';
import { GraphTooltip } from '../GraphTooltip';
import { GraphTooltipDescription } from '../GraphTooltip/components';
import {
  AddonWrapper,
  StyledDivider,
  StyledColumn,
  Addon,
  AddonLegend,
  StyledTitle,
  StyledExpansionPanelTitle,
  StyledExpansionPanelWrapper,
  StyledExpansionPanelTitleWrapper,
  StyledExpansionPanelArrow,
  StyledExpansionPanelInfo,
  StyledExpansionPanelLegendWrapper,
  AddonOptions,
  ColorBox,
  PanelArrow,
} from './Components';
import InfoIcon from '@mui/icons-material/Info';

const ExpansionPanel = ({
  data,
  timeframeWidth,
  xPoint,
  theme,
  settings,
  hiddenByDefault,
  dimensions,
  totalTimeframe,
}: IOwnProps): JSX.Element => {
  // Always show expansion panel if placeholder item only item (also see validators.tsx)
  const noAddonsButPanelShouldBeShown = (data.addons || []).length === 1 && data.addons?.[0].id === 'placeholder';

  const [addonsHidden, setAddonsHidden] = React.useState<boolean>(hiddenByDefault);
  const onSetAddonsHidden = () => {
    if (noAddonsButPanelShouldBeShown) return;
    setAddonsHidden((prev) => !prev);
  };

  if (!data.addons?.length) return <></>;
  const addonOptions: TAddonOption[] = data.addonOptions || [];
  return (
    <AddonWrapper dimensions={dimensions}>
      <StyledDivider color={theme.divider} dimensions={dimensions} />
      <StyledExpansionPanelWrapper
        width={timeframeWidth + dimensions.leftColumn.width - dimensions.addons.divider.width}
      >
        <StyledExpansionPanelTitleWrapper>
          <div style={{ display: 'inline-flex', width: '100%' }}>
            {!noAddonsButPanelShouldBeShown && (
              <StyledExpansionPanelArrow onClick={onSetAddonsHidden}>
                <PanelArrow open={!addonsHidden} />
              </StyledExpansionPanelArrow>
            )}
            <div style={{ display: 'flex', width: '100%' }}>
              <StyledExpansionPanelTitle
                onClick={onSetAddonsHidden}
                color={theme.title}
                dimensions={dimensions}
                adjustWidthForMissingIcon={noAddonsButPanelShouldBeShown}
              >
                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.name}</div>
                {data.expansionPanelTitleDescription ? (
                  <StyledExpansionPanelInfo>
                    <GraphTooltip
                      title={''}
                      description={<GraphTooltipDescription convertedData={data.expansionPanelTitleDescription} />}
                      content={
                        <InfoIcon sx={{ fontSize: 18, color: '#045A8B', opacity: 0.8, '&:hover': { opacity: 1 } }} />
                      }
                    />
                  </StyledExpansionPanelInfo>
                ) : null}
              </StyledExpansionPanelTitle>

              <div
                style={{
                  flex: '1',
                  borderTop: `1px solid ${colors.appBlue.default + '80'}`,
                  marginRight: '0.2rem',
                  display: addonOptions.length > 0 ? undefined : 'none',
                }}
              >
                &nbsp;
              </div>
            </div>
          </div>
          {data.legend && !addonsHidden && (
            <div style={{ display: 'inline-flex' }}>
              <StyledExpansionPanelLegendWrapper>
                <AddonLegend legend={data.legend} />
              </StyledExpansionPanelLegendWrapper>
            </div>
          )}
        </StyledExpansionPanelTitleWrapper>
        <div style={{ display: addonsHidden || noAddonsButPanelShouldBeShown ? 'none' : undefined }}>
          {data.addons?.map((addon, i) => {
            // TODO: Tää pitäis viedä jonki funktion sisäl
            const addonTheme: TAddonTheme =
              addon.themeId && settings?.addonSettings?.[addon.themeId]?.theme
                ? getExpansionPanelTheme(settings?.addonSettings?.[addon.themeId].theme).addon
                : settings?.addonSettings?.[addon.id.toLowerCase()]?.theme
                  ? getExpansionPanelTheme(settings?.addonSettings?.[addon.id.toLowerCase()]?.theme).addon
                  : typeof addon.title === 'string' && settings?.addonSettings?.[addon.title.toLowerCase()]?.theme
                    ? getExpansionPanelTheme(settings.addonSettings[addon.title.toLowerCase()]?.theme).addon
                    : theme.addon;
            return (
              <div
                key={i}
                style={{
                  height: '3rem',
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '0.25rem',
                  marginBottom: '0.25rem',
                }}
              >
                {/** TODO: yo. divin specsit pitäis viedä dimensions-conffiin ainaki */}
                <StyledColumn
                  padding="0 0.5rem 0 1rem"
                  width={dimensions.leftColumn.width - dimensions.addons.divider.width}
                >
                  {addon.titleDescription ? (
                    <GraphTooltip
                      title={typeof addon.title === 'string' ? addon.title : ''}
                      description={addon.titleDescription}
                      content={
                        <StyledTitle color={addonTheme.title}>
                          <ColorBox color={addonTheme.primary} />
                          <div
                            style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              paddingLeft: '1rem',
                            }}
                          >
                            {addon.title}
                          </div>
                        </StyledTitle>
                      }
                    />
                  ) : (
                    <StyledTitle color={addonTheme.title}>
                      <ColorBox color={addonTheme.primary} />
                      <div
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          paddingLeft: '1rem',
                        }}
                      >
                        {addon.title}
                      </div>
                    </StyledTitle>
                  )}
                </StyledColumn>
                <StyledColumn width={timeframeWidth}>
                  <Addon
                    addon={addon}
                    width={timeframeWidth}
                    xPoint={xPoint}
                    theme={addonTheme}
                    totalTimeframe={totalTimeframe}
                  />
                </StyledColumn>
              </div>
            );
          })}
        </div>
      </StyledExpansionPanelWrapper>

      {addonOptions.length > 0 ? (
        <div
          style={{
            display: addonsHidden ? 'none' : undefined,
            height: 'auto',
            width: 'auto',
          }}
        >
          <AddonOptions
            width={dimensions.rightColumn.width}
            options={addonOptions as any[]}
            color={theme.divider}
            dimensions={dimensions}
          />
        </div>
      ) : null}
    </AddonWrapper>
  );
};

interface IOwnProps {
  data: IAddonData;
  timeframeWidth: number;
  xPoint: (d: Date | undefined) => number | undefined;
  theme: TExpansionPanelTheme;
  settings: Required<TGraphSettings>;
  hiddenByDefault: boolean;
  dimensions: IGraphDimensions;
  totalTimeframe: [Date, Date];
}

export default ExpansionPanel;
