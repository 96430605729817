import * as React from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector as useSelector } from 'Store/index';
import { find, includes, without } from 'ramda';

import DashboardGraph from '../../components/DashboardGraph';
import ContentWrapper from '../../components/ContentWrapper';
import Tile from '../../components/DashboardTile';
import { Container, Item } from '../../components/Grid';
import ErrorContainer from '../../containers/ErrorContainer';

import { styleDash } from '../../config/theme/componentTheme';
import { filteredDocumentTypes } from '../../config/generalSettings';

import { getEnabledDocuments, getRelevantDocuments } from '../../utility/randomUtil';
import { isEnded } from '../../utility/isEnded';

import Icon from './Icon';
import MyDash from 'Routes/MyService/Dash';

import { getActiveTreatments, getRightDocumentsForPlatform } from '../Treatment/utils/index';
import { withCapabilities } from 'Containers/CapabilityHandler';
import { IGroupACLMapping } from '../../config/definitions/accessControl';
import { checkMyServCapability, isMyServiceAvailable } from 'Routes/MyService/util';
import { capitalize } from 'Utility/string';

// Find if there are any documents being edited
const editingDocuments = (documents: IControlProps[], n: string, session?: ISessionStore): boolean => {
  const docTypes = session ? getEnabledDocuments(session) : [];
  const thisDocTypes = find((t) => t.name === n, docTypes);

  // Filter specific documents based on filtering function
  const subTypeFilter = (singleDoc: IControlProps) =>
    thisDocTypes && thisDocTypes.subTypesFiltering && thisDocTypes.subTypesFiltering[singleDoc._type]
      ? thisDocTypes.subTypesFiltering[singleDoc._type](singleDoc)
      : true;

  // True, if editing document is of the same type as the tile, or if it's included in the subTypes
  return !!find(
    (d) => d._editing && (d._type === n || includes(d._type, thisDocTypes?.subTypes || [])) && subTypeFilter(d),
    documents || [],
  );
};

// Find if there are no documents
// NOTE: No documents message is only shown if there is NO documents (this does not include ended medication/treatments/etc)
const noDocs = (
  documents: IControlProps[],
  n: string,
  session?: ISessionStore,
  myappDocs?: IControlProps[],
): boolean => {
  const docTypes = session ? getEnabledDocuments(session) : [];
  const thisDocTypes = find((t) => t.name === n, docTypes);

  if (
    (session?.platforms?.selected === 'epilepsy' && myappDocs && n === 'seizure') ||
    (session?.platforms?.selected === 'sleepApnea' && myappDocs && n === 'background')
  ) {
    const defaultCondition = find(
      (d) => d._type === n || includes(d._type, thisDocTypes?.subTypes || []),
      documents || [],
    );
    const patientReportedSeizureFound = !!myappDocs?.find((d) => d._type === 'patientReportedSeizure');
    const sharedBgDocFound = !!myappDocs?.find((d) => d._type === 'background');
    return !(defaultCondition || patientReportedSeizureFound || sharedBgDocFound);
  }

  // True if theres no documents matching main or subType
  return !find((d) => d._type === n || includes(d._type, thisDocTypes?.subTypes || []), documents || []);
};

const getDocumentsLength = (platform?: string, documentType?: string, documents?: IControlProps[]) => {
  if (!documents || documents.length < 1) return null;
  switch (platform) {
    case 'sma':
    case 'dmd': {
      if (documentType === 'therapy') {
        const therapyDocs = documents as ITherapy[];
        const activeTherapies = therapyDocs.filter((t) => !isEnded(t.endDate));
        return activeTherapies && activeTherapies.length;
      }
      return null;
    }
    case 'ms': {
      if (documentType === 'therapy') {
        const therapyDocs = documents as ITherapy[];
        const activeTherapies = therapyDocs.filter((t) => !isEnded(t.endDate));
        return activeTherapies && activeTherapies.length;
      }
      if (documentType === 'comorbidity') {
        const comorbidityDocs = documents as IComorbidity[];
        const active = comorbidityDocs.filter((t) => !isEnded(t.endDate));
        return active && active.length;
      }
      return null;
    }
    case 'epilepsy': {
      if (documentType === 'surgicalTreatment') {
        return documents.length;
      }
      if (documentType === 'otherSymptoms') {
        const docs = documents as IOtherSymptoms[];
        const activeDocs = docs.filter((t) => !isEnded(t.endDate));
        return activeDocs && activeDocs.length;
      }
      return null;
    }
    case 'sleepApnea': {
      if (documentType === 'diagnosis') {
        const sleepApneaAndRespiratoryFailureDocs = (
          documents as (IDiagnosis | ISleepApneaFirstVisit | IRespiratoryFirstVisit)[]
        ).filter(
          (d) =>
            'diagnosis' in d &&
            ['G47.3', 'J96.1', 'J96.9', 'sleepApneaSuspicion', 'respiratoryFailureSuspicion'].includes(
              d.diagnosis ?? '',
            ),
        ) as IDiagnosis[];
        return sleepApneaAndRespiratoryFailureDocs.length;
      }
      if (documentType === 'treatment') {
        const docs = documents as any[];
        const treatmentDocsFiltered = getRightDocumentsForPlatform(docs);
        const activeDocs = getActiveTreatments(treatmentDocsFiltered);
        return activeDocs && activeDocs.length;
      }
      return null;
    }
    default:
      return null;
  }
};

const TileContent = ({
  section,
  documents,
}: {
  section: string;
  documents: ({ [key: string]: any } & IControlProps)[];
}): JSX.Element => {
  return React.createElement(require('../' + capitalize(section) + '/Dash/').default, { documents: documents });
};

const RenderTile = ({
  section,
  session,
  capabilityGroups = {},
}: {
  section: string;
  session?: ISessionStore;
  capabilityGroups?: IGroupACLMapping;
}): JSX.Element => {
  const platform = session?.platforms?.selected;

  const docs = useSelector((s: IState) => s.documents?.sortedAndMergedDocuments)?.slice() ?? [];
  const myappDocs = useSelector((s: IState) => s.myapp?.sortedAndMergedDocuments);

  const enabledName = useSelector((state: IState) =>
    getEnabledDocuments(state.session).find(
      (d: { name: string; subTypes?: string[]; secondaryDocs?: string[] }) => d.name === section,
    ),
  );
  const relevantDocs = getRelevantDocuments(docs, enabledName, section);

  return (
    <Item key={section} xs="auto">
      {section === 'myService' &&
      platform &&
      !isMyServiceAvailable(platform, checkMyServCapability(platform, capabilityGroups), docs) ? (
        <ErrorContainer>
          {checkMyServCapability(platform, capabilityGroups) ? <MyDash isClickable={false} /> : <></>}
        </ErrorContainer>
      ) : (
        <Link to={'/' + section}>
          <ErrorContainer>
            {section === 'myService' ? ( // Get custom tile for My Service
              platform && checkMyServCapability(platform, capabilityGroups) ? (
                <MyDash isClickable={true} />
              ) : (
                <></>
              )
            ) : (
              <Tile
                section={section}
                content={<TileContent section={section} documents={relevantDocs} />}
                editing={editingDocuments(docs, section, session)}
                noDocs={section === 'profile' ? false : noDocs(docs, section, session, myappDocs)}
                icon={<Icon name={section} />}
                platform={platform}
                documentsLength={getDocumentsLength(platform, section, relevantDocs)}
              />
            )}
          </ErrorContainer>
        </Link>
      )}
    </Item>
  );
};

const TileWithCapabilities = withCapabilities(RenderTile);

const Content = ({
  sections,
  filteredSections,
  session,
}: {
  sections: string[];
  filteredSections: string[];
  session: ISessionStore;
}): JSX.Element => (
  <ContentWrapper>
    <Container justifyContent="center">
      <Item>
        <Container style={{ ...styleDash }} rowSpacing={'0rem'}>
          {without(filteredSections, sections).map((section: string) => (
            <TileWithCapabilities key={section} section={section} session={session} />
          ))}
        </Container>
      </Item>
    </Container>
  </ContentWrapper>
);

const Dashboard = ({ sections }: IOwnProps): JSX.Element => {
  const session = useSelector((s: IState) => s.session);
  const platform = session.platforms?.selected;
  const documents = useSelector((s: IState) => s.documents.sortedAndMergedDocuments);
  const filteredSections = filteredDocumentTypes(platform, documents);

  return (
    <>
      <ErrorContainer>
        <React.StrictMode>
          <DashboardGraph />
        </React.StrictMode>
      </ErrorContainer>
      <Content sections={sections} filteredSections={filteredSections} session={session} />
    </>
  );
};

interface IOwnProps {
  sections: string[];
}

export default Dashboard;
