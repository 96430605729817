import { DurationObjectUnits } from 'luxon';
import { omit, path, pick, sum } from 'ramda';
import { parkinsonDiagnoses } from '../../Diagnosis/utils/definitions';
import { exists, calculateIntervalYMD, nowPartialDate, sortPartialDate } from 'neuro-utils';
import { controlProps } from 'Utility/documentHandling';

export type TDocument = INPI | IFBIMOD | IBNSQ;

/**
 * Calculate patient's disease duration (from first Parkinson diagnosis document to current date)
 * @param {Array<IDiagnosis>} diagnosisDocuments - Diagnosis documents
 * @returns {string} - Disease duration from first Parkinson diagnosis in format 'y M d'
 */
export const calculateParkinsonDiseaseDuration = (diagnosisDocuments: Array<IDiagnosis>): DurationObjectUnits => {
  const startDate: PartialDate =
    path(
      [0, 'date'],
      diagnosisDocuments
        .filter((d) => d && d?.diagnosis && (parkinsonDiagnoses as TDiagnosis[]).includes(d?.diagnosis))
        .sort((a, b) => sortPartialDate(a.date, b.date)),
    ) ?? nowPartialDate();
  const endDate = nowPartialDate();
  return calculateIntervalYMD(startDate, endDate);
};

/**
 * Calculate NPI frequency and severity score - multiply frequency and severity of each section and then sum them
 * @param {INPI} d - NPI document
 * @returns {number} - Total frequency and severity score
 */
export const calculateNpiFrequencyAndSeverityScore = (d: INPI): number | undefined => {
  return Object.keys(d).every((k) => !exists(path([k, 'frequency'], d)) && !exists(path([k, 'severity'], d)))
    ? undefined
    : Object.keys(d).reduce((prev, curr) => {
        const frequency: number = path([curr, 'frequency'], d) ?? 0;
        const severity: number = path([curr, 'severity'], d) ?? 0;
        return prev + frequency * severity;
      }, 0);
};

/**
 * Calculate NPI stress score - sum stress score of each section
 * @param {INPI} d - NPI document
 * @returns {number} - Total stress score
 */
export const calculateNpiStressScore = (d: INPI): number | undefined => {
  return Object.keys(d).every((k) => !exists(path([k, 'stress'], d)))
    ? undefined
    : Object.keys(d).reduce((prev, curr) => {
        const stress: number = path([curr, 'stress'], d) ?? 0;
        return prev + stress;
      }, 0);
};

/**
 * Calculate total amount of answers that match given ADL level
 * @param {IADL} d - ADL document
 * @param {TADLLevel} level - Level which is 0, 1, 2, 3 or unknown
 * @returns {number | undefined} - The total amount of answers that match given ADL level in different categories if ADL document is valid and full or partial and if given level is a valid ADL level, else undefined
 */
export const calculateAdlTotal = (d: IADL, level: TADLLevel): number | undefined => {
  const adl = omit(['date', ...controlProps], d);
  if (Object.keys(adl).length === 0) return;
  if (!['0', '1', '2', '3', 'unknown'].includes(level)) return;
  return Object.keys(adl).reduce((prev, curr) => {
    const value = adl[curr as keyof typeof adl] ?? '0';
    return prev + (typeof value === 'string' && value === level ? 1 : 0);
  }, 0);
};

/**
 * Calculate ADL index which is the total amount of answers that match ADL levels 2 or 3
 * @param {IADL} d - ADL document
 * @returns {number | undefined} - The total amount of answers that match ADL levels 2 or 3 if ADL document is valid and full, else undefined
 */
export const calculateAdlIndex = (d: IADL): number | 'unknown' | undefined => {
  const adl = omit(['date', ...controlProps], d);
  // ADL steps/categories:
  // washing, dressing, wcUsage, movementAtHome, continence, eating
  // = 6
  if (Object.keys(adl).length < 6 || Object.keys(adl).some((k) => !adl[k as keyof typeof adl])) return;
  if (Object.keys(adl).some((k) => adl[k as keyof typeof adl] === 'unknown')) return 'unknown';
  return Object.keys(adl).reduce((prev, curr) => {
    const value = adl[curr as keyof typeof adl] ?? '0';
    return prev + (typeof value === 'string' && (value === '2' || value === '3') ? 1 : 0);
  }, 0);
};

/**
 * In data, IADL answer options are differentiated as 1a, 1b, 0 etc.
 * However, in the form all that matters and is displayed is the number, so 1a --> 1 and 0a --> 0 etc.
 * @param {string | number} name - A string or number to format
 * @returns {number} - If string was given and it was of expected format, formats it and returns it as number, else just returns it (as number)
 */
export const iadlNumberFormatter = (name: string | number): number =>
  typeof name === 'number' ? name : /^[0-9]{1}[a-z]{1}$/.test(name) ? parseInt(name[0]) : parseInt(name);

/**
 * Calculate IADL score - sum of answers of each section/category
 * @param {IIADL} d - An IADL document
 * @returns {number | undefined} - The score if there document is completely filled, else undefined
 */
export const calculateIadlScore = (d: IIADL): number | undefined => {
  const iadl = omit(['date', ...controlProps], d);
  // IADL steps/categories:
  // phoneUsage, shopping, cooking, housekeeping, laundering, transportation, responsibilityOfOwnMedication, abilityToTakeCareOfFinancialMatters
  // = 8
  if (Object.keys(iadl).length < 8 || Object.keys(iadl).some((k) => !iadl[k as keyof typeof iadl])) return;
  return Object.keys(iadl).reduce((prev, curr) => {
    const value = iadl[curr as keyof typeof iadl] ?? '0';
    return prev + (typeof value === 'string' ? iadlNumberFormatter(value) : 0);
  }, 0);
};

export const fbiModFields = [
  'apathy',
  'lackOfInitiative',
  'indifference',
  'inflexibility',
  'disregardForSelf',
  'disorganization',
  'inattentiveness',
  'unawareOfSurroundings',
  'decreaseInSpeech',
  'perseverance',
  'irritability',
  'overlyJoking',
  'badJudgement',
  'hoarding',
  'unsuitableBehaviour',
  'impulsiveness',
  'restlessness',
  'aggressiveness',
  'hyperorality',
  'hypersexuality',
  'compulsiveBehaviour',
  'incontinency',
];

/**
 * Calculate FBI-Mod score - sum of answers of each section/category
 * @param {IFBIMOD} d - An FBI-Mod document
 * @returns {number | undefined} - The score if the document is completely filled, else undefined
 */
export const calculateFBIMODScore = (d: IFBIMOD): number | undefined => {
  const handledDocument = Object.values(pick(fbiModFields, d))
    .map((value) => typeof value === 'string' && parseInt(value))
    .filter((int) => typeof int === 'number' && !isNaN(int)) as number[];

  if (handledDocument.length < 22) return undefined;
  return sum(handledDocument);
};
