/**
 * Use Reacts own error catching to catch and log errors with components
 */

import * as React from 'react';
import { makeLog } from '../../utility/logger';

class ErrorContainer extends React.Component<IOwnProps, IOwnState> {
  state = {
    error: false,
  };

  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    this.setState({ error: true });
    makeLog('Error', error, info);
  }

  public render(): JSX.Element | JSX.Element[] {
    return !this.state.error ? this.props.children : <div>Error</div>;
  }
}

interface IOwnState {
  error: boolean;
}

interface IOwnProps {
  children: JSX.Element | JSX.Element[];
}

export default ErrorContainer;
