import { remove } from 'ramda';
import { createID } from '../../../utility/appendIDs';
import { sortTime, sortPartialDate } from 'neuro-utils';
import { INeuroDocument } from 'neuro-data-structures';

// Push new empty object to event array or create new array if none exists
export const createEventItem =
  (
    name: string,
    setEditIndex: (n: boolean) => void,
    formData: IFormData<any>,
    creationMutator?: (event: any) => any,
    defaultValues = {},
  ) =>
  (): void => {
    if (!formData.document[name] || formData.document[name].length === 0) {
      let newEvent = { id: createID(), ...defaultValues };
      newEvent = creationMutator ? creationMutator(newEvent) : newEvent;
      formData.onChange && formData.onChange({ [name]: [newEvent] });
    } else {
      // Shallow copy
      const array = [...formData.document[name]];

      let newEvent = { id: createID(), ...defaultValues };
      newEvent = creationMutator ? creationMutator(newEvent) : newEvent;
      array.unshift(newEvent);
      formData.onChange && formData.onChange({ [name]: array });
    }
    //setEditIndex(true);
  };

// Sort the array by date or startDate and then save it
export const sortByDateAndSave = (name: string, formData: IFormData<any>, dateFieldName?: string): void => {
  // Shallow copy
  let events = [...formData.document[name]] as {
    date?: PartialDate;
    startDate?: PartialDate;
    createDate?: number;
    time?: Time;
    startTime?: Time;
    [key: string]: PartialDate | number | Time | undefined;
  }[];
  events = events
    ? events
        .sort((s1, s2) => (s1?.createDate ?? 0) - (s2?.createDate ?? 0))
        .sort((n1, n2) => {
          // Some events might use startDate and startTime instead of date and time
          if (dateFieldName) return sortPartialDate(n1[dateFieldName] as PartialDate, n2[dateFieldName] as PartialDate);
          if (n1.startDate) {
            if (n1.startTime)
              return sortPartialDate(n1.startDate, n2.startDate) || sortTime(n1.startTime, n2.startTime);
            return sortPartialDate(n1.startDate, n2.startDate);
          }

          if (n1.time) return sortPartialDate(n1.date, n2.date) || sortTime(n1.time, n2.time);
          return sortPartialDate(n1.date, n2.date);
        })
        .reverse()
    : [];
  formData.onChange && formData.onChange({ [name]: events });
};

export type TDeleteProps = { removeTS: INeuroDocument['removeTS']; removeInfo: INeuroDocument['removeInfo'] };

export const deleteEvent =
  (i: number, name: string, formData: IFormData<any>, deleteProps?: TDeleteProps) => (): void => {
    // Shallow copy
    const events = [...formData.document[name]];

    if (formData.onChange) {
      if (deleteProps) {
        const newEvents = events;
        events[i] = { ...events[i], ...deleteProps };
        formData.onChange({ [name]: newEvents });
      } else {
        const newEvents = remove(i, 1, events);
        formData.onChange({ [name]: newEvents });
      }
    }
  };

export const onChangeUnit =
  (
    index: number,
    eventName: string,
    formData: IFormData<any>,
    onChangeMutator?: (event: any, name?: string, value?: any) => any,
  ) =>
  (onChangeValues: TOnChangeValues): void => {
    const onChangeName = Object.keys(onChangeValues)[0]; // First value should be the main field
    const onChangeValue = onChangeValues[onChangeName];

    // Update current event item and then update all events
    // Shallow copy
    const allEvents = [...(formData.document?.[eventName] || [])];
    const thisEvent = allEvents[index];
    let thisNewEvent = { ...thisEvent, ...onChangeValues };

    if (onChangeMutator) thisNewEvent = onChangeMutator(thisNewEvent, onChangeName, onChangeValue);

    allEvents[index] = thisNewEvent;
    formData.onChange && formData.onChange({ [eventName]: allEvents });
  };
