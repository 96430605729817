import { mergeRight } from 'ramda';
import { ICompactVaultUser, IVaultOrganization } from 'neuro-data-structures';
import { createAction, createReducer } from '@reduxjs/toolkit';

import { assertCapabilities, ws_manager } from '../index';

import { localStorageRemove } from 'Utility/localStorage';
import { lifecareSync } from 'Store/documents/helpers/apiFetchers';
import {
  caretableSync,
  fetchOrganizationData,
  fetchOrganizationGroup,
  fetchOrgUsers,
  fetchPreferredPlatforms,
  fimlabSync,
  getStatisticsTokenForPlatform,
  imagingSync,
  lifecareLaboratorySync,
  medicationSync,
} from './apiFetchers';
import { syncAvxSessionData } from '../readonly/fetchers';
import { TDispatch } from '..';
import { sessionStorageGet, sessionStorageRemove, sessionStorageSave } from '../../utility/sessionStorage';
import { fetchWithOptions } from '../../utility/fetch';
import { checkJWTNotExpired, getJWTData, parseJWTFromCookie } from '../../utility/jwtAuthTools';
import { actions as docActions } from '../documents/actions';
import { actions as readonlyActions } from '../readonly/actions';
import { makeLog } from 'Utility/logger';
import { PubSub } from 'neuro-event-commons';
import PlatformCapabilities from '../../config/capabilities';

/* Action types */
export const LOAD = 'neuro-ui/session/LOAD'; // Session (jwt) data actions
const UPDATE = 'neuro-ui/session/UPDATE';
const CHECK = 'neuro-ui/session/CHECK';
const INVALIDATED = 'neuro-ui/session/INVALIDATED';
const RESET_VISIT = 'neuro-ui/session/RESET_VISIT'; // Remove session data
const AVAILABLE_PLATFORMS = 'neuro-ui/session/AVAILABLE_PLATFORMS'; // Get available platforms
const SET_PLATFORM = 'neuro-ui/session/SET_PLATFORM'; // Set platforms
const SET_PREFERRED_PLATFORMS = 'neuro-ui/session/SET_PREFERRED_PLATFORMS'; // Set preferred/recommended
const UNSELECT_PATIENT_AND_VISIT = 'neuro-ui/session/UNSELECT_PATIENT_AND_VISIT';
const SET_LOGOUT = 'neuro-ui/session/SET_LOGOUT'; // Logout
const SYNC_LOAD_STATUS = 'neuro-ui/session/SYNC_LOAD_STATUS'; // Sync status'
const TOGGLE_CENSOR = 'neuro-ui/session/TOGGLE_CENSOR'; // Censor patient data (name/ssn) in UI
const SET_ORG_DATA = 'neuro-ui/session/SET_ORG_DATA'; // Organization data (group name / display name)
const SET_ORG_GROUP = 'neuro-ui/session/SET_ORG_GROUP';
const SET_ORG_USERS = 'neuro-ui/session/SET_ORG_USERS';
const SET_SELECTED_MEDICATION_ORG = 'neuro-ui/session/SET_SELECTED_MEDICATION_ORG'; // Selected organization for displaying medication data
const SET_GRAPH_SELECTIONS = 'neuro-ui/session/SET_GRAPH_SELECTIONS'; // Set some selections for graph
export const SUBSCRIBERS_CHANGED = 'neuro-ui/session/SUBSCRIBERS_CHANGED' as const;
export const WEBSOCKET_CLOSED = 'neuro-ui/session/WEBSOCKET_CLOSED' as const;
const SET_STATISTICS_TOKEN_DATA = 'neuro-ui/session/SET_STATISTICS_TOKEN_DATA';
const SET_EXTRA_SNACK = 'neuro-ui/session/SET_EXTRA_SNACK';
const HIDE_OR_DELETE_SNACK = 'neuro-ui/session/HIDE_OR_DELETE_SNACK';

export const subscribersChangedAction = createAction(
  SUBSCRIBERS_CHANGED,
  (message: PubSub.ISubscriptionSummaryMessage) => ({
    payload: message,
  }),
);

export const websocketClosedAction = createAction(WEBSOCKET_CLOSED);

/*
  Action creators
*/
const toggleCensor = createAction(TOGGLE_CENSOR, (pid: string | null) => {
  if (pid) sessionStorageSave('censorID', pid);
  else sessionStorageRemove('censorID');
  return {
    payload: { shouldCensor: !!pid },
  };
});

export const loadSessionAction = createAction(LOAD, (payload: IJWTBody | undefined) => {
  return {
    payload: !checkJWTNotExpired() ? { error: 'NAL - No auth on session load', data: payload } : { data: payload },
  };
});

export const sessionInvalidatedAction = createAction(INVALIDATED, (duplicate_session_loaded_action: unknown) => {
  return { payload: { duplicate_session_loaded_action } };
});

const loadSession =
  () =>
  (dispatch: TDispatch): void => {
    const profileData = getJWTData() || undefined;

    const censorID = sessionStorageGet('censorID');
    if (censorID && censorID === profileData?.patientid) {
      dispatch(toggleCensor(profileData?.patientid));
    } else dispatch(toggleCensor(null));

    const jwt = parseJWTFromCookie();
    if (profileData?.patientid && jwt) {
      ws_manager.connect(jwt);
    }
    dispatch(loadSessionAction(profileData));
  };

const updateSession = createAction(UPDATE, () => {
  return {
    payload: !checkJWTNotExpired()
      ? { error: 'NAU - No auth on session update', data: getJWTData() || undefined }
      : { data: getJWTData() },
  };
});

const checkSession = createAction(CHECK, () => {
  return {
    payload: !checkJWTNotExpired() ? { error: 'NAC - No auth on session check' } : undefined,
  };
});

const resetVisit = createAction(RESET_VISIT, () => {
  return {
    payload: { data: undefined },
  };
});

const availablePlatforms = createAction(AVAILABLE_PLATFORMS, (platforms: Platform[]) => {
  return {
    payload: { data: platforms },
  };
});

const setPlatform = createAction(SET_PLATFORM, (platform: Platform) => {
  return {
    payload: { data: platform },
  };
});

const unselectPatientAndVisitAction = createAction(UNSELECT_PATIENT_AND_VISIT, (payload) => ({
  payload,
}));

const unselectPatientAndVisit = async (dispatch: TDispatch): Promise<void> => {
  const neurojwt = parseJWTFromCookie();
  if (!neurojwt) return;
  const jmrTokenRes = await fetch('/api/patient/v2/unselect/', { headers: { neurojwt }, method: 'POST' });
  if (jmrTokenRes.status !== 200) return;
  const jmrToken = await jmrTokenRes.text();
  const jwtModifRes = await fetch('/auth/modify', { headers: { neurojwt }, method: 'PATCH', body: jmrToken });
  if (jwtModifRes.status !== 200) return;
  const payload = getJWTData();
  dispatch(unselectPatientAndVisitAction(payload));
};

const setPreferredPlatforms = createAction(SET_PREFERRED_PLATFORMS, (platforms: Platform[]) => {
  return {
    payload: { data: platforms },
  };
});

const preferredPlatforms = async (dispatch: TDispatch, lifeCareSync = false): Promise<Platform[] | null> => {
  if (lifeCareSync) {
    await lifecareSync();
  }
  return fetchPreferredPlatforms().then((platforms) => {
    dispatch(setPreferredPlatforms(platforms || []));
    return platforms;
  });
};

const setLoggedOut = createAction(SET_LOGOUT, () => {
  return {
    payload: { loggedOut: true },
  };
});

const setSyncLoadStatus = createAction(
  SYNC_LOAD_STATUS,
  (sync: { [syncName: string]: { status: 'loading' | 'done' | 'error'; error?: Error; res?: Response } }) => {
    return { payload: sync };
  },
);

const setOrgData = createAction(SET_ORG_DATA, (data: IVaultOrganization) => ({
  payload: data,
}));
const setOrgGroupArray = createAction(
  SET_ORG_GROUP,
  (data: Array<Pick<IVaultOrganization, 'orgId' | 'displayName'>>) => ({
    payload: data,
  }),
);

const setOrgUsersAction = createAction(SET_ORG_USERS, (orgUsers: ICompactVaultUser[]) => {
  return {
    payload: { orgUsers },
  };
});

const setSelectedMedicationOrg = createAction(SET_SELECTED_MEDICATION_ORG, (data: string) => ({
  payload: data,
}));

const setGraphSelections = createAction(SET_GRAPH_SELECTIONS, (data: ISessionStore['graphSelections']) => ({
  payload: data,
}));

const setStatisticsTokenData = createAction(
  SET_STATISTICS_TOKEN_DATA,
  (data: { [platform in Platform]?: ITokenData | null }) => ({
    payload: data,
  }),
);

const setExtraSnack = createAction(SET_EXTRA_SNACK, (data: IAlert) => ({
  payload: data,
}));
const hideOrDeleteSnack = createAction(HIDE_OR_DELETE_SNACK, (data: string) => ({
  payload: data,
}));

const loadOrganizationData = (dispatch: TDispatch) => (): void => {
  // Fetch organization information
  fetchOrganizationData()
    .then((data: IVaultOrganization | null) => {
      if (data !== null) {
        dispatch(setOrgData(data));
        // Fetch all organizations in the group
        fetchOrganizationGroup(data.orgGroup)
          .then((groupData: Array<Pick<IVaultOrganization, 'orgId' | 'displayName'>> | null) => {
            if (groupData !== null) dispatch(setOrgGroupArray(groupData));
          })
          .catch(() => {
            return;
          });

        // Fetch users in the current organization
        fetchOrgUsers(data.orgId).then((users) => {
          if (users) {
            dispatch(setOrgUsersAction(users));
          }
        });
      }
    })
    .catch(() => {
      return;
    });
};

const logOut =
  () =>
  (dispatch: TDispatch): void => {
    fetchWithOptions(`/auth/v0/logout`, { neurojwt: parseJWTFromCookie() }, { method: 'GET' })
      .then((res: Response) => {
        if (res.status === 200) {
          dispatch(setLoggedOut());
          localStorageRemove('platform');
          ws_manager.disconnect();
        }
      })
      .catch((err) => {
        makeLog('Error', { name: 'Logging out', message: 'Logging out failed' }, err);
      });
  };

const refreshJWT = (): void => {
  fetchWithOptions(`/auth/refresh`, { neurojwt: parseJWTFromCookie() }, { method: 'GET' });
};

const medicationSyncAction = async (
  dispatch: TDispatch,
  system: 'miranda' | 'apotti' | 'lifecare',
  fetchDocs = true,
  medicationFilteringCapability = false,
): Promise<boolean> => {
  dispatch(setSyncLoadStatus({ medication: { status: 'loading' } }));
  return medicationSync(system).then((res) => {
    if (res && fetchDocs) {
      docActions.fetchDocumentData(false, medicationFilteringCapability)(dispatch);
    }
    if (res) dispatch(setSyncLoadStatus({ medication: { status: 'done' } }));
    if (!res) dispatch(setSyncLoadStatus({ medication: { status: 'error' } }));
    return res;
  });
};

const caretableSyncAction = async (dispatch: TDispatch, fetchDocs = true): Promise<boolean> => {
  dispatch(setSyncLoadStatus({ caretable: { status: 'loading' } }));
  return caretableSync().then((res) => {
    if (res && fetchDocs) {
      docActions.fetchDocumentData(false)(dispatch);
    }
    if (res) dispatch(setSyncLoadStatus({ caretable: { status: 'done' } }));
    if (!res) dispatch(setSyncLoadStatus({ caretable: { status: 'error' } }));
    return res;
  });
};

const lifecareLaboratorySyncAction = async (dispatch: TDispatch, fetchDocs = true): Promise<boolean> => {
  dispatch(setSyncLoadStatus({ laboratory: { status: 'loading' } }));
  return lifecareLaboratorySync().then((res) => {
    if (res && fetchDocs) {
      docActions.fetchDocumentData(false)(dispatch);
    }
    if (res) dispatch(setSyncLoadStatus({ laboratory: { status: 'done' } }));
    if (!res) dispatch(setSyncLoadStatus({ laboratory: { status: 'error' } }));
    return res;
  });
};

const fimlabLabSyncAction = async (dispatch: TDispatch, fetchDocs = true): Promise<boolean> => {
  dispatch(setSyncLoadStatus({ laboratory: { status: 'loading' } }));

  const syncSuccessful = await fimlabSync();
  if (syncSuccessful && fetchDocs) {
    docActions.fetchDocumentData(false)(dispatch);
  }
  if (syncSuccessful) dispatch(setSyncLoadStatus({ laboratory: { status: 'done' } }));
  if (!syncSuccessful) dispatch(setSyncLoadStatus({ laboratory: { status: 'error' } }));
  return syncSuccessful;
};

const imagingSyncAction = async (dispatch: TDispatch, fetchDocs = true): Promise<boolean> => {
  dispatch(setSyncLoadStatus({ imaging: { status: 'loading' } }));
  return imagingSync().then((res) => {
    if (res && fetchDocs) {
      docActions.fetchDocumentData(false)(dispatch);
    }
    if (res) dispatch(setSyncLoadStatus({ imaging: { status: 'done' } }));
    if (!res) dispatch(setSyncLoadStatus({ imaging: { status: 'error' } }));
    return res;
  });
};

export const resMedSyncAction = async (dispatch: TDispatch, fetchDocs = true): Promise<boolean> => {
  dispatch(setSyncLoadStatus({ resMed: { status: 'loading' } }));
  return syncAvxSessionData().then((res) => {
    if (res && fetchDocs) {
      readonlyActions.loadReadonlyData(true)(dispatch);
    }
    if (res) dispatch(setSyncLoadStatus({ resMed: { status: 'done' } }));
    if (!res) dispatch(setSyncLoadStatus({ resMed: { status: 'error' } }));
    return res;
  });
};

/** Do all syncs that are we are capable */
const doSyncFetchesAndGetDocuments = (
  dispatch: TDispatch,
  capabilityGroups = {},
  refetchDocs = true,
  fetchReadonlyDocs = true,
) => {
  const syncs = [];

  if (assertCapabilities([PlatformCapabilities.MIRANDA_MEDICATION_UPDATE], capabilityGroups))
    syncs.push(medicationSyncAction(dispatch, 'miranda', false));
  if (assertCapabilities([PlatformCapabilities.APOTTI_MEDICATION_UPDATE], capabilityGroups))
    syncs.push(medicationSyncAction(dispatch, 'apotti', false));
  if (assertCapabilities([PlatformCapabilities.LIFECARE_MEDICATION_SYNC], capabilityGroups))
    syncs.push(medicationSyncAction(dispatch, 'lifecare', false));
  if (assertCapabilities([PlatformCapabilities.URANUS_CARETABLE_SYNC], capabilityGroups))
    syncs.push(caretableSyncAction(dispatch, false));
  if (assertCapabilities([PlatformCapabilities.LIFECARE_LABORATORY_SYNC], capabilityGroups))
    syncs.push(lifecareLaboratorySyncAction(dispatch, false));
  if (assertCapabilities([PlatformCapabilities.FIMLAB_LABORATORY_SYNC], capabilityGroups))
    syncs.push(fimlabLabSyncAction(dispatch, false));
  if (assertCapabilities([PlatformCapabilities.LFORCE_IMAGING_SYNC], capabilityGroups))
    syncs.push(imagingSyncAction(dispatch, false));
  if (assertCapabilities([PlatformCapabilities.RESMED_AVX_INTEGRATION], capabilityGroups)) {
    syncs.push(resMedSyncAction(dispatch, false));
  }

  if (syncs.length === 0) return;

  // Fetch documents again after all async fetches are finished
  Promise.all(syncs).finally(() => {
    if (refetchDocs) {
      docActions.fetchDocumentData(
        false,
        assertCapabilities([PlatformCapabilities.MIRANDA_MEDICATION_HIDE_OVERLAPPING], capabilityGroups),
      )(dispatch);
    }
    if (fetchReadonlyDocs) {
      readonlyActions.loadReadonlyData(
        assertCapabilities([PlatformCapabilities.RESMED_AVX_INTEGRATION], capabilityGroups),
      )(dispatch);
    }
  });
};

const getStatisticsTokenData = (platform: Platform) => (dispatch: TDispatch) => {
  getStatisticsTokenForPlatform(platform).then((token) => {
    dispatch(setStatisticsTokenData({ [platform]: token }));
  });
};

export const actions = {
  loadSession,
  updateSession,
  checkSession,
  resetVisit,
  setPlatform,
  preferredPlatforms,
  availablePlatforms,
  logOut,
  refreshJWT,
  doSyncFetchesAndGetDocuments,
  toggleCensor,
  loadOrganizationData,
  setSelectedMedicationOrg,
  unselectPatientAndVisit,
  setGraphSelections,
  getStatisticsTokenData,
  setExtraSnack,
  hideOrDeleteSnack,
};

/* The reducer */
const initialState: ISessionStore = {
  status: {
    init_at_client_time: Date.now(),
    invalidated_at_client_time: null,
  },
  syncLoadStatus: {},
  shouldCensor: false,
  websocket: {
    subscribers: [],
  },
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loadSessionAction, (state, action) => {
      state.data = action.payload?.data;
    })
    .addCase(updateSession, (state, action) => {
      state.data = action.payload?.data;
    })
    .addCase(checkSession, (state, action) => {
      if (action.payload) {
        // Set the error from payload, but retain session data because we need the relogin link from JWT.
        return { ...state, ...action.payload };
      }
      return state;
    })
    .addCase(setOrgUsersAction, (state, action) => {
      state.orgUsers = action.payload?.orgUsers;
    })
    .addCase(sessionInvalidatedAction, (state) => {
      state.status.invalidated_at_client_time = Date.now();
    })
    .addCase(resetVisit, (state) => {
      return mergeRight<ISessionStore, Partial<ISessionStore>>(state, {
        data: { ...(state.data as IJWTBody), visitid: null },
      });
    })
    .addCase(availablePlatforms, (state, action) => {
      return mergeRight<ISessionStore, Partial<ISessionStore>>(state, {
        platforms: { ...(state.platforms || {}), available: action.payload?.data ?? [] },
      });
    })
    .addCase(setPlatform, (state, action) => {
      return mergeRight<ISessionStore, Partial<ISessionStore>>(state, {
        platforms: { available: [], ...state.platforms, selected: action.payload?.data ?? '' },
      });
    })
    .addCase(setPreferredPlatforms, (state, action) => {
      return mergeRight<ISessionStore, Partial<ISessionStore>>(state, {
        platforms: { available: [], ...state.platforms, preferred: action.payload?.data ?? [] },
      });
    })
    .addCase(setLoggedOut, (state, action) => {
      return Object.assign({}, initialState, action.payload);
    })
    .addCase(setSyncLoadStatus, (state, action) => {
      state.syncLoadStatus = { ...state.syncLoadStatus, ...action.payload };
    })
    .addCase(toggleCensor, (state, action) => {
      state.shouldCensor = action.payload.shouldCensor;
    })
    .addCase(setOrgData, (state, action) => {
      return { ...state, orgGroup: action.payload.orgGroup, orgDisplayName: action.payload.displayName };
    })
    .addCase(setOrgGroupArray, (state, action) => {
      return { ...state, orgGroupArray: action.payload };
    })
    .addCase(setSelectedMedicationOrg, (state, action) => {
      return { ...state, selectedMedicationOrg: action.payload };
    })
    .addCase(unselectPatientAndVisitAction, (state, action) => {
      return { ...state, data: action.payload };
    })
    .addCase(setGraphSelections, (state, action) => {
      state.graphSelections = { ...state.graphSelections, ...action.payload };
    })
    .addCase(subscribersChangedAction, (state, action) => {
      state.websocket.subscribers = action.payload.subscribers;
    })
    .addCase(websocketClosedAction, (state) => {
      state.websocket.subscribers = initialState.websocket.subscribers;
    })
    .addCase(setStatisticsTokenData, (state, action) => {
      state.statisticsTokenData = { ...state.statisticsTokenData, ...action.payload };
    })
    .addCase(setExtraSnack, (state, action) => {
      let snacks = [...(state.snacks?.extraSnacks || [])];
      const newSnack = action.payload;
      const snackid = snacks.findIndex((s) => s.id === newSnack.id);
      if (snackid > -1) {
        snacks[snackid] = newSnack;
      } else {
        snacks = [...snacks, newSnack];
      }
      state.snacks = { extraSnacks: snacks, hiddenSnacks: state.snacks?.hiddenSnacks };
    })
    .addCase(hideOrDeleteSnack, (state, action) => {
      const extraSnacks = state.snacks?.extraSnacks || [];
      if (extraSnacks.find((es) => es.id === action.payload)) {
        // Delete extra snacks
        const filteredSnacks = [...extraSnacks].filter((s) => s.id !== action.payload);
        state.snacks = {
          extraSnacks: filteredSnacks,
          hiddenSnacks: state.snacks?.hiddenSnacks,
        };
      } else {
        // Hide alerts
        state.snacks = {
          extraSnacks: state.snacks?.extraSnacks,
          hiddenSnacks: [...(state.snacks?.hiddenSnacks || []), action.payload],
        };
      }
    })
    .addDefaultCase((state) => {
      return state;
    });
});

export default reducer;
