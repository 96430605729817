import { find, path } from 'ramda';
import { steps as mgcSteps } from '../Document/Form/mgc';
import { steps as mgadlSteps } from '../Document/Form/mgadl';

export type TDocument = IMGC | IMGADL | IPassTest;

const docType = (documents: Array<TDocument>, editing: string): string | undefined =>
  documents && editing
    ? path(
        ['_type'],
        find((d: TDocument) => d._id === editing, documents),
      ) || undefined
    : undefined;

export const getHeaderId = (documents: Array<TDocument>, editing: any, viewing: any): string => {
  // handle both editing and viewing modes
  const docId = editing ? editing : viewing;
  // if the user is in editing or viewing mode and docType returns the doctype, return correct title for the header
  if (docType(documents, docId)) {
    return `functionalPerformanceMgravis.${docType(documents, docId)}`;
  }
  return 'functionalPerformanceMgravis.title';
};
export const getHeaderInfotext = (documents: Array<TDocument>, editing: any, viewing: any) => {
  const docId = editing ? editing : viewing;
  if (docType(documents, docId) === 'mgc') {
    return `functionalPerformanceMgravis.labels.${docType(documents, docId)}.instruction`;
  }
  return undefined;
};

export const isMGCComplete = (doc: IMGC) => {
  return mgcSteps.every((step) => {
    const stepScore = path([step], doc);
    if (stepScore || stepScore === 0 || stepScore === '0') {
      return true;
    }
    return false;
  });
};
export const mgcScore = (doc: IMGC) => {
  let score = 0;
  mgcSteps.forEach((step) => {
    const stepScore = path([step], doc);
    if (typeof stepScore === 'number') {
      score += stepScore;
    }
    if (typeof stepScore === 'string') {
      const value = parseInt(stepScore);
      score += value;
    }
  });
  return score;
};

export const isMGADLComplete = (doc: IMGADL) => {
  return mgadlSteps.every((step) => {
    const stepScore = path([step], doc);
    if (stepScore || stepScore === 0) {
      return true;
    }
    return false;
  });
};
export const mgadlScore = (doc: IMGADL) => {
  let score = 0;
  mgadlSteps.forEach((step) => {
    const stepScore = path([step], doc);
    if (typeof stepScore === 'number') {
      score += stepScore;
    }
  });
  return score;
};
