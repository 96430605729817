import * as React from 'react';
import { styled as muiStyled } from '@mui/material';
import {
  GraphColors,
  IData,
  IGraphData,
  IGraphDimensions,
  ILegend,
  TGraphScale,
  TGraphSettings,
} from 'Components/sq-graphics/interfaces';
import { Legends } from '../Legends';
import LineGraph from './GraphTypes/LineGraph';
import ConstantValueChart from './GraphTypes/ConstantValueChart';
import BoxGraph from './GraphTypes/BoxGraph';
import StackedBarChart from './GraphTypes/StackedBarChart';
import StackedAreaChart from './GraphTypes/StackedAreaChart';
import HeatGraph from './GraphTypes/HeatGraph';
import { isNumber, sortDate } from 'neuro-utils';
import { invertColor } from 'Components/sq-graphics/util/calculators';
import { areDatesEqual } from 'Components/sq-graphics/util/checkers';
import colors from '../../../../config/theme/colors';
import { useSelector } from 'react-redux';

const StyledContainer = muiStyled('div')((props: { width: number }) => ({
  width: `${props.width}rem`,
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
}));

const StyledScaleArea = muiStyled('div')(({ width, height }: { width: number; height: number }) => ({
  width: `${width}rem`,
  height: `${height}rem`,
}));

const StyledGraphArea = muiStyled('div')((props: { width: number }) => ({
  width: `${props.width}rem`,
  position: 'relative',
  clipPath: 'inset(-6px -2px -6px -2px)',
}));

const createYGrid = (scale: TGraphScale, isHeatGraph: boolean) =>
  isHeatGraph ? (
    <g>
      {scale.customScale?.map((item, index) => (
        <line
          key={item}
          x1="0%"
          x2="100%"
          y1={`${(100 / (scale.customScale || []).length ?? 1) * index}%`}
          y2={`${(100 / (scale.customScale || []).length ?? 1) * index}%`}
          stroke="#8D8D8D"
          strokeWidth="0.2"
          opacity="1"
        />
      ))}
    </g>
  ) : (
    <g>
      <line x1="0%" x2="100%" y1="0%" y2="0%" stroke="gray" strokeWidth="0.2" opacity="0.75" />
      <line x1="0%" x2="100%" y1="20%" y2="20%" stroke="gray" strokeWidth="0.2" opacity="0.75" />
      <line x1="0%" x2="100%" y1="40%" y2="40%" stroke="gray" strokeWidth="0.2" opacity="0.75" />
      <line x1="0%" x2="100%" y1="60%" y2="60%" stroke="gray" strokeWidth="0.2" opacity="0.75" />
      <line x1="0%" x2="100%" y1="80%" y2="80%" stroke="gray" strokeWidth="0.2" opacity="0.75" />
    </g>
  );

const createHelperLines = (
  data: IGraphData,
  graphSettings: TGraphSettings['graphSettings'],
  height: number,
  xPoint: (date: Date | undefined) => number | undefined,
  yPoint: (value: string | number, scale: TGraphScale, graphHeight: number) => number | undefined,
  referenceData?: IGraphData,
) => {
  const helperLinesX = graphSettings?.helperLines?.x?.filter((hl) => data.data.find((d) => d.id === hl.id)) ?? [];
  const refHelperLinesX = graphSettings?.helperLines?.x?.filter(
    (hl) => referenceData?.data.find((d) => d.id === hl.id),
  );
  // yLines not implemented yet

  const totalHelperLinesX = helperLinesX?.map((hlx) => ({ ...hlx, type: 'normal' }));
  if (refHelperLinesX) {
    const refHelperLinesXWithType = refHelperLinesX.map((rhlx) => ({ ...rhlx, type: 'ref' }));
    totalHelperLinesX.push(...refHelperLinesXWithType);
  }

  if (totalHelperLinesX?.length === 0) return;

  const xLines = totalHelperLinesX
    ?.map((hl, i) => {
      const yValues =
        hl.lines
          ?.map((line) =>
            yPoint(line.value, hl.type === 'ref' && referenceData ? referenceData.scale : data.scale, height),
          )
          .filter((yv): yv is number => isNumber(yv)) ?? [];
      if (yValues.length === 0) return;
      const sortedYValues = yValues.toSorted((a, b) => b - a);

      let heightLeft = height;

      return (
        <g key={i}>
          {...sortedYValues?.map((yv, ind) => {
            const unsortedInd = yValues.findIndex((og) => og === yv);
            heightLeft = heightLeft - yv;
            return (
              <g key={ind}>
                {hl.lines[unsortedInd].drawArea ? (
                  <rect width={'100%'} y={yv} height={heightLeft} fill={hl.lines?.[unsortedInd].color} opacity={0.2} />
                ) : undefined}
                <line
                  key={i}
                  x1={'0%'}
                  x2={'100%'}
                  y1={yv}
                  y2={yv}
                  stroke={hl.lines?.[unsortedInd].color}
                  strokeWidth={1}
                  opacity={0.9}
                />
                ;
              </g>
            );
          })}
        </g>
      );
    })
    .filter((xl) => xl);

  if (!xLines || xLines.length === 0) return;

  return <g>{...xLines}</g>;
};

// These graphtypes cant be combined with any other graph type
const singleGraphTypes: Array<IData['type']> = ['heatGraph'];

const GraphComponent = ({
  viewBox,
  height,
  data,
  xPoint,
  yPoint,
  graphSettings,
  referenceData,
  timeframeWidth,
  selectedTab,
  scaleId,
}: {
  viewBox: string;
  height: number;
  data: IGraphData;
  xPoint: (date: Date | undefined) => number | undefined;
  yPoint: (value: string | number, scale: TGraphScale, graphHeight: number) => number | undefined;
  graphSettings: TGraphSettings['graphSettings'];
  referenceData: IGraphData | undefined;
  timeframeWidth: number;
  selectedTab?: number;
  scaleId?: string;
}): JSX.Element => {
  // Assign all the graph types into a variable named graphTypes
  const graphTypes: Array<IData['type']> = [];
  if (data.data.every((d: IData | { data: IData[]; id: string }) => 'data' in d)) {
    (data.data as Array<{ data: IData[]; id: string }>).forEach((d) =>
      d.data.forEach((dd) => {
        if (graphTypes.includes(dd.type)) return;
        dd.legend !== '' && graphTypes.push(dd.type);
      }),
    );
  } else {
    (data.data as IData[])?.forEach((d) => {
      if (graphTypes.includes(d.type)) return;
      d.legend !== '' && graphTypes.push(d.type);
    });
  }
  const refGraphTypes: Array<IData['type']> = [];
  if (referenceData) {
    referenceData.data?.forEach((d) => {
      if ('type' in d && refGraphTypes.includes(d.type)) return;
      'type' in d && d.legend !== '' && refGraphTypes.push(d.type);
    });
  }

  const graphTypesLength = {
    lineGraph: 0,
    constantValueChart: 0,
    boxGraph: 0,
    stackedBarChart: 0,
    stackedAreaChart: 0,
    heatGraph: 0,
  };
  let totalNumberOfGraphs = 0;
  graphTypes.forEach((gt) => {
    const graphDataArr = data.data.every((d) => 'data' in d)
      ? (data.data[selectedTab ?? 0] as { data: IData[]; id: string }).data.filter((d) => d.type === gt)
      : data.data?.filter((d) => 'type' in d && d.type === gt);
    totalNumberOfGraphs += graphDataArr.length;
    graphTypesLength[gt] = graphDataArr.length;
  });

  const refGraphTypesLength = {
    lineGraph: 0,
    constantValueChart: 0,
    boxGraph: 0,
    stackedBarChart: 0,
    stackedAreaChart: 0,
    heatGraph: 0,
  };
  let totalNumberOfRefGraphs = 0;
  refGraphTypes.forEach((rgt) => {
    const refGraphDataArr =
      referenceData && referenceData.data.every((d) => 'data' in d)
        ? (referenceData.data[selectedTab ?? 0] as { data: IData[]; id: string }).data.filter(
            (d) => d.type === rgt && d.legend !== '',
          )
        : referenceData && referenceData.data?.filter((d) => 'type' in d && d.type === rgt && d.legend !== '');
    totalNumberOfRefGraphs = totalNumberOfRefGraphs + (refGraphDataArr ? refGraphDataArr.length : 0);
    refGraphTypesLength[rgt] = refGraphDataArr ? refGraphDataArr.length : 0;
  });

  return (
    <svg viewBox={viewBox} overflow="visible" xmlns="http://www.w3.org/2000/svg">
      <rect x="0" y="0" width="100%" height="100%" fill="#F5F5F5" opacity="0.75" />
      {createYGrid(data.scale, !!data.data?.find((d) => 'type' in d && d.type === 'heatGraph'))}
      {/** Border lines of the graph area */}
      <line x1="0" x2="0" y1="0" y2="100%" stroke="darkGray" strokeWidth="1.5" />
      <line x1="0%" x2="100%" y1="100%" y2="100%" stroke="darkGray" strokeWidth="1.5" />
      <line x1="100%" x2="100%" y1="0" y2="100%" stroke="darkGray" strokeWidth="1.5" />
      {createHelperLines(data, graphSettings, height, xPoint, yPoint, referenceData)}
      <g>
        {graphTypes.map((gt, i) => {
          const gtData: IData[] = data.data.every((d) => 'data' in d)
            ? (data.data as Array<{ data: IData[]; id: string }>)?.[selectedTab ?? 0].data?.filter(
                (dd) => dd.type === gt,
              )
            : (data.data as IData[])?.filter((d) => 'type' in d && d.type === gt);
          switch (gt) {
            case 'lineGraph': {
              return (
                <LineGraph
                  key={`lineGraphComponent${i}`}
                  data={gtData}
                  xPoint={xPoint}
                  yPoint={yPoint}
                  height={height}
                  width={timeframeWidth * 10}
                  scale={data.scale}
                  additionalScale={data.additionalScale}
                  graphSettings={graphSettings}
                  graphTypeIndex={i}
                  numberOfOtherGraphs={totalNumberOfGraphs - graphTypesLength.lineGraph}
                  scaleId={scaleId}
                />
              );
            }
            case 'constantValueChart': {
              return (
                <ConstantValueChart
                  key={`constantValueComponent${i}`}
                  data={gtData}
                  xPoint={xPoint}
                  yPoint={yPoint}
                  height={height}
                  scale={data.scale}
                  additionalScale={data.additionalScale}
                  graphSettings={graphSettings}
                  graphTypeIndex={i}
                  numberOfOtherGraphs={totalNumberOfGraphs - graphTypesLength.constantValueChart}
                />
              );
            }
            case 'boxGraph': {
              return (
                <BoxGraph
                  key={`boxGraphComponent${i}`}
                  data={gtData}
                  xPoint={xPoint}
                  yPoint={yPoint}
                  height={height}
                  scale={data.scale}
                  additionalScale={data.additionalScale}
                  graphSettings={graphSettings}
                  numberOfOtherGraphs={totalNumberOfGraphs - graphTypesLength.boxGraph}
                  graphTypeIndex={i}
                  timeframeWidth={timeframeWidth}
                />
              );
            }
            case 'stackedBarChart': {
              return (
                <StackedBarChart
                  key={`stackedBarChartComponent${i}`}
                  data={gtData}
                  xPoint={xPoint}
                  yPoint={yPoint}
                  height={height}
                  scale={data.scale}
                  additionalScale={data.additionalScale}
                  graphSettings={graphSettings}
                  interval={gtData?.[i]?.stackedBarChartProps?.interval}
                  numberOfOtherGraphs={totalNumberOfGraphs - graphTypesLength.stackedBarChart}
                  graphTypeIndex={i}
                />
              );
            }
            case 'stackedAreaChart': {
              return (
                <StackedAreaChart
                  key={`stackedAreaComponent${i}`}
                  //data={gtData}
                  //xPoint={xPoint}
                  //yPoint={yPoint}
                  //height={height}
                />
              );
            }
            case 'heatGraph': {
              return (
                <HeatGraph
                  key={`heatGraphComponent${i}`}
                  data={gtData}
                  xPoint={xPoint}
                  yPoint={yPoint}
                  height={height}
                  scale={data.scale}
                  additionalScale={data.additionalScale}
                  graphSettings={graphSettings}
                  timeframeWidth={timeframeWidth}
                />
              );
            }
            default:
              return <g key={`group${i}`}></g>;
          }
        })}
      </g>
      <g>
        {referenceData &&
          refGraphTypes.map((gt, i) => {
            const gtData: IData[] = referenceData.data.every((d) => 'data' in d)
              ? (referenceData.data as Array<{ data: IData[]; id: string }>)?.[selectedTab ?? 0].data?.filter(
                  (dd) => dd.type === gt,
                )
              : (referenceData.data as IData[])?.filter((d) => 'type' in d && d.type === gt);
            switch (gt) {
              case 'lineGraph': {
                return (
                  <LineGraph
                    key={`lineGraphComponent${i}`}
                    data={gtData}
                    xPoint={xPoint}
                    yPoint={yPoint}
                    height={height}
                    width={timeframeWidth * 10}
                    scale={referenceData.scale}
                    additionalScale={referenceData.scale}
                    graphSettings={graphSettings}
                    reference
                    graphTypeIndex={i}
                    numberOfOtherGraphs={totalNumberOfRefGraphs - refGraphTypesLength.lineGraph}
                  />
                );
              }
              case 'constantValueChart': {
                return (
                  <ConstantValueChart
                    key={`lineGraphComponent${i}`}
                    data={gtData}
                    xPoint={xPoint}
                    yPoint={yPoint}
                    height={height}
                    scale={referenceData.scale}
                    additionalScale={referenceData.scale}
                    graphSettings={graphSettings}
                    reference
                    numberOfOtherGraphs={totalNumberOfRefGraphs - refGraphTypesLength.constantValueChart}
                    graphTypeIndex={i}
                  />
                );
              }
              case 'boxGraph': {
                return (
                  <BoxGraph
                    key={`lineGraphComponent${i}`}
                    data={gtData}
                    xPoint={xPoint}
                    yPoint={yPoint}
                    height={height}
                    scale={referenceData.scale}
                    additionalScale={referenceData.scale}
                    graphSettings={graphSettings}
                    reference
                    numberOfOtherGraphs={totalNumberOfRefGraphs - refGraphTypesLength.boxGraph}
                    graphTypeIndex={i}
                    timeframeWidth={timeframeWidth}
                  />
                );
              }
              case 'stackedBarChart': {
                return (
                  <StackedBarChart
                    key={`lineGraphComponent${i}`}
                    data={gtData}
                    xPoint={xPoint}
                    yPoint={yPoint}
                    height={height}
                    scale={referenceData.scale}
                    additionalScale={referenceData.scale}
                    graphSettings={graphSettings}
                    reference
                    interval={gtData?.[i]?.stackedBarChartProps?.interval}
                    graphTypeIndex={i}
                    numberOfOtherGraphs={totalNumberOfRefGraphs - refGraphTypesLength.stackedAreaChart}
                  />
                );
              }
              case 'stackedAreaChart': {
                return (
                  <StackedAreaChart
                    key={`lineGraphComponent${i}`}
                    //data={gtData}
                    //xPoint={xPoint}
                    //yPoint={yPoint}
                    //height={height}
                  />
                );
              }
              case 'heatGraph': {
                return (
                  <HeatGraph
                    key={`lineGraphComponent${i}`}
                    data={gtData}
                    xPoint={xPoint}
                    yPoint={yPoint}
                    height={height}
                    scale={referenceData.scale}
                    additionalScale={referenceData.scale}
                    graphSettings={graphSettings}
                    reference
                    timeframeWidth={timeframeWidth}
                  />
                );
              }
              default:
                return <g key={`group${i}`}></g>;
            }
          })}
      </g>
    </svg>
  );
};

interface IGraphScaleProps {
  side: 'right' | 'left';
  scale: TGraphScale;
  getYTicks: (scale: TGraphScale | undefined, graphHeight: number) => { y: number; value: string }[];
  height: number;
  isHeatGraph: boolean;
  helperLinesX?: NonNullable<NonNullable<TGraphSettings['graphSettings']>['helperLines']>['x'];
  leftSideScale?: TGraphScale;
  helperLinesXRef?: NonNullable<NonNullable<TGraphSettings['graphSettings']>['helperLines']>['x'];
  yPoint?: (value: string | number, scale: TGraphScale, graphHeight: number) => number | undefined;
}

const ScaleWrapper = muiStyled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
});

const YTickWrapper = muiStyled('div')((props: { side: 'left' | 'right'; top: number }) => ({
  top: props.top,
  width: '100%',
  position: 'absolute',
  transform: 'translate(0, -50%)',
  display: 'flex',
  alignItems: 'center',
  flexDirection: props.side === 'left' ? 'row-reverse' : 'row',
}));

const StyledYTick = muiStyled('div')(({ fontSize }: { fontSize: number }) => ({
  fontSize: `${fontSize}rem`,
  lineHeight: `${fontSize - 0.2}rem`,
  color: '#8D8D8D',
  userSelect: 'none',
}));

const StyledTick = muiStyled('div')((props: { side: 'left' | 'right' }) => ({
  width: '5px',
  height: '1px',
  backgroundColor: '#8D8D8D',
  boxSizing: 'border-box',
  margin: `0 ${props.side === 'right' ? '2px' : '0'} 0 ${props.side === 'left' ? '2px' : '0'}`,
}));

const parseFontSize = (yTicks: { y: number; value: string }[]): number => {
  const longestTitle = Math.max(...yTicks.map((yt) => yt.value.length));
  if (longestTitle > 12) return 1;
  if (longestTitle > 6) return 1.4;
  return 1.6;
};

const HeatGraphYTickWrapper = muiStyled('div')(({ top }: { top: number }) => ({
  top: top,
  width: '100%',
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
}));

const HeatGraphTick = muiStyled('div')({
  width: '100%',
  height: '1px',
  backgroundColor: '#8D8D8D',
  boxSizing: 'border-box',
});

const HeatGraphYTick = muiStyled('div')({
  fontSize: '1rem',
  lineHeight: '1rem',
  color: '#8D8D8D',
  userSelect: 'none',
  boxSizing: 'border-box',
  textAlign: 'left',
  width: '100%',
  display: '-webkit-box',
  lineClamp: 2,
  WebkitLineClamp: 2,
  boxOrient: 'vertical',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
});

interface IYTickProps {
  yt: { y: number; value: string };
  side: 'left' | 'right';
  fontSize: number;
  isHeatGraph: boolean;
}

const YTick = ({ yt, side, fontSize, isHeatGraph }: IYTickProps): JSX.Element =>
  isHeatGraph ? (
    <HeatGraphYTickWrapper top={yt.y - 1}>
      <HeatGraphTick />
      <div style={{ padding: '0.5rem' }}>
        <HeatGraphYTick>{yt.value}</HeatGraphYTick>
      </div>
    </HeatGraphYTickWrapper>
  ) : (
    <YTickWrapper top={yt.y} side={side}>
      <StyledTick side={side} />
      <StyledYTick fontSize={fontSize}>{yt.value}</StyledYTick>
    </YTickWrapper>
  );

const GraphScale = ({
  side,
  scale,
  getYTicks,
  height,
  isHeatGraph,
  helperLinesX,
  leftSideScale,
  helperLinesXRef,
  yPoint,
}: IGraphScaleProps): JSX.Element => {
  const yTicks = getYTicks(scale, height);
  const fontSize = parseFontSize(yTicks);

  const uiLanguage = useSelector((s: IState) => s.settings.userSettings.uiLanguage) || 'fi';

  const totalHelperLinesX = helperLinesX?.map((hlx) => ({ ...hlx, type: 'normal' }));
  if (helperLinesXRef) {
    const helperLinesXRefWithType = helperLinesXRef.map((hlxr) => ({ ...hlxr, type: 'ref' }));
    totalHelperLinesX?.push(...helperLinesXRefWithType);
  }

  return (
    <ScaleWrapper>
      {yTicks.map((yt, i) => (
        <YTick key={`${yt.value}+${yt.y}+${i}`} yt={yt} side={side} fontSize={fontSize} isHeatGraph={isHeatGraph} />
      ))}
      {totalHelperLinesX?.map((hlx, index) => {
        return hlx.lines.map((hl, ind) => {
          const y = yPoint?.(hl.value, hlx.type === 'normal' && leftSideScale ? leftSideScale : scale, height);
          if (!isNumber(y) || !totalHelperLinesX[index].lines[ind].title?.[uiLanguage === 'sv' ? 'en' : uiLanguage])
            return;
          const yTickSamePos = yTicks.some((yt) => yt.y === y || Math.abs(y - yt.y) <= 8);
          return (
            <div
              key={ind}
              style={{
                position: 'absolute',
                width: '100%',
                height: 'min-content',
                top: y,
                left: yTickSamePos ? 30 : 0,
                lineHeight: '2.4rem',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                transform: 'translate(0, -50%)',
                color: colors.secondaryText,
              }}
            >
              <div
                style={{
                  width: yTickSamePos ? 5 : 35,
                  height: 1,
                  backgroundColor: totalHelperLinesX[index].lines[ind].color,
                  marginRight: 5,
                }}
              />
              {totalHelperLinesX[index].lines?.[ind].title?.[uiLanguage === 'sv' ? 'en' : uiLanguage]}
            </div>
          );
        });
      })}
    </ScaleWrapper>
  );
};

const StyledGraphTitleContainer = muiStyled('div')((props: { padding?: string }) => ({
  width: '100%',
  padding: props.padding ?? '0.5 4rem',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

const StyledGraphTitle = muiStyled('div')((props: { color?: string }) => ({
  fontSize: '1.7rem',
  color: props.color ?? '#8D8D8D',
  fontWeight: 'bold',
  userSelect: 'none',
  textAnchor: 'middle',
}));

const parseViewBox = (width: number, height: number, isHeatGraph: boolean): string => {
  return `0 0 ${width * 10} ${isHeatGraph ? height * 10 + 30 : height * 10}`;
};

const canRefDataBeDrawn = (data: IGraphData): boolean => {
  // If there are two different scales, do not Draw refData
  if (data.additionalScale) return false;
  // If there is a graph that cant be drawn with any other graph, do not draw refData
  if (data.data?.every((d) => 'data' in d)) {
    if (
      (data.data as Array<{ data: IData[]; id: string }>).some(
        (d) => d.data?.find((dd) => singleGraphTypes.includes(dd.type)),
      )
    )
      return false;
  } else if ((data.data as IData[])?.find((d) => singleGraphTypes.includes(d.type))) return false;
  return true;
};

const getHeatGraphCustomScale = (data: IGraphData, scale: TGraphScale, selectedTab?: number): string[] => {
  const heatGraphDataPoints = data.data?.every((d) => 'data' in d)
    ? (data.data as Array<{ data: IData[]; id: string }>)
        .find((d) => d.data.find((dd) => dd.type === 'heatGraph' && (dd.dataPoints || []).length > 0))
        ?.data[selectedTab ?? 0].dataPoints.sort((dp1, dp2) => sortDate(dp1.date, dp2.date))
    : (data.data as IData[])
        .find((d) => d.type === 'heatGraph' && (d.dataPoints || []).length > 0)
        ?.dataPoints.sort((dp1, dp2) => sortDate(dp1.date, dp2.date));
  if (!heatGraphDataPoints) return scale.customScale || [];
  const latestDate = heatGraphDataPoints.reverse()[0].date;
  const latestDateDataPoints = heatGraphDataPoints.filter((dp) => areDatesEqual(dp.date, latestDate));
  const values = latestDateDataPoints
    .map((dp) => ({ value: dp.value, additionalValue: dp.additionalValue }))
    .sort((v1, v2) => {
      const av1 = parseFloat((v1.additionalValue ?? 0).toString());
      const av2 = parseFloat((v2.additionalValue ?? 0).toString());
      if (isNaN(av1) && isNaN(av2)) return 0;
      if (isNaN(av1)) return -1;
      if (isNaN(av2)) return 1;
      if (av1 > av2) return 1;
      if (av1 < av2) return -1;
      return 0;
    });
  return values.map((v) => v.value.toString());
};

const Graphs = ({
  height,
  data,
  referenceData,
  xPoint,
  yPoint,
  getYTicks,
  drawGraphTitles,
  settings,
  timeframeWidth,
  dimensions,
  selectedTab,
}: IOwnProps): JSX.Element => {
  const graphSettings = settings['graphSettings'];
  const refData = canRefDataBeDrawn(data) ? referenceData : undefined;
  const isHeatGraph = !!data.data?.find((d) => 'type' in d && d.type === 'heatGraph');
  // Solmusa
  const scale: TGraphScale | undefined = data.data?.find((d) => 'type' in d && d.type === 'heatGraph')
    ? { ...data.scale, customScale: getHeatGraphCustomScale(data, data.scale, selectedTab) }
    : undefined;

  const allLegends = data.data?.map((d) => ('legend' in d ? d.legend : undefined));
  if (data.data.every((d) => 'data' in d)) {
    (data.data as Array<{ data: IData[]; id: string }>).forEach((d) =>
      d.data.forEach((dd) => allLegends.push(dd.legend)),
    );
  }
  const uniqueLegendsData = data.data.every((d) => 'data' in d)
    ? (data.data as Array<{ data: IData[]; id: string }>).map((d) =>
        d.data.filter((d, i) => (i === 0 || !allLegends.slice(0, i).includes(d.legend)) && d.legend !== ''),
      )[selectedTab ?? 0]
    : (data.data as IData[])?.filter(
        (d, i) => (i === 0 || !allLegends.slice(0, i).includes(d.legend)) && d.legend !== '',
      );

  const legends: ILegend[] = [];
  uniqueLegendsData?.forEach((d, i) => {
    legends.push({
      id: d.id,
      legend: d.legend,
      color: graphSettings?.colors?.[d.id]
        ? GraphColors[graphSettings.colors[d.id]]
        : Object.values(GraphColors)[i % Object.values(GraphColors).length],
      legendDescription: d.legendDescription,
    });
    if (d.extraLegends) {
      d.extraLegends.forEach((l) => {
        legends.push({
          id: `${d.id}-${l}`,
          legend: l,
          isExtra: true,
        });
      });
    }
  });

  if (referenceData) {
    if (referenceData.data.every((d) => 'data' in d)) {
      (referenceData.data as Array<{ data: IData[]; id: string }>).forEach((dt) => {
        dt.data
          .filter((d) => d.legend !== '')
          .forEach((d, i) => {
            if (legends.some((l) => l.legend === d.legend)) return;
            legends.push({
              id: d.id,
              legend: d.legend,
              color: graphSettings?.colors?.[d.id]
                ? invertColor(GraphColors[graphSettings.colors[d.id]])
                : invertColor(Object.values(GraphColors)[i % Object.values(GraphColors).length]),
              legendDescription: d.legendDescription,
            });
          });
      });
    } else {
      (referenceData.data as IData[])
        ?.filter((rd) => rd.legend !== '')
        .forEach((rd, i) => {
          if (legends.some((l) => l.legend === rd.legend)) return;
          legends.push({
            id: rd.id,
            legend: rd.legend,
            color: graphSettings?.colors?.[rd.id]
              ? invertColor(GraphColors[graphSettings.colors[rd.id]])
              : invertColor(Object.values(GraphColors)[i % Object.values(GraphColors).length]),
            legendDescription: rd.legendDescription,
          });
        });
    }
  }

  const helperLinesX = graphSettings?.helperLines?.x?.filter((hl) => data.data.find((d) => d.id === hl.id));
  const helperLineXRefData = graphSettings?.helperLines?.x?.filter(
    (hl) => referenceData?.data.find((d) => d.id === hl.id),
  );

  return (
    <React.Fragment>
      {drawGraphTitles && (
        <StyledGraphTitleContainer
          padding={`0rem ${dimensions.graphs.scaleArea.width * 0.5}rem 0.5rem ${
            dimensions.graphs.scaleArea.width * 0.5
          }rem`}
        >
          <StyledGraphTitle>{data.title}</StyledGraphTitle>
          {refData && <StyledGraphTitle>{refData.title}</StyledGraphTitle>}
        </StyledGraphTitleContainer>
      )}
      <StyledContainer
        width={
          timeframeWidth +
          dimensions.graphs.scaleArea.width * 2 +
          dimensions.rightColumn.width -
          dimensions.graphs.scaleArea.width
        }
      >
        <StyledScaleArea width={dimensions.graphs.scaleArea.width} height={height}>
          <GraphScale
            getYTicks={getYTicks}
            height={height * 10}
            scale={scale ?? data.scale}
            side="left"
            isHeatGraph={isHeatGraph}
          />
        </StyledScaleArea>
        <StyledGraphArea width={timeframeWidth}>
          <GraphComponent
            viewBox={parseViewBox(timeframeWidth, height, isHeatGraph)}
            height={height * 10}
            data={scale ? { ...data, scale } : data}
            xPoint={xPoint}
            yPoint={yPoint}
            graphSettings={graphSettings}
            referenceData={refData}
            timeframeWidth={timeframeWidth}
            selectedTab={selectedTab}
            scaleId={data?.scale?.id}
          />
          <Legends legends={legends} dimensions={dimensions} alwaysShow={data.alwaysShowLegends} />
        </StyledGraphArea>
        <StyledScaleArea
          width={dimensions.graphs.scaleArea.width + dimensions.rightColumn.width - dimensions.graphs.scaleArea.width}
          height={height}
        >
          <GraphScale
            getYTicks={getYTicks}
            height={height * 10}
            // If additionalScale is provided, refData wont be drawn.
            // If refData is not provided, the same scale as on the left will be drawn
            scale={scale ?? data.additionalScale ?? refData?.scale ?? data.scale}
            side="right"
            isHeatGraph={isHeatGraph}
            helperLinesX={helperLinesX}
            helperLinesXRef={helperLineXRefData}
            leftSideScale={data.scale}
            yPoint={yPoint}
          />
        </StyledScaleArea>
      </StyledContainer>
    </React.Fragment>
  );
};

interface IOwnProps {
  height: number;
  data: IGraphData;
  referenceData?: IGraphData | undefined;
  xPoint: (date: Date | undefined) => number | undefined;
  yPoint: (value: string | number, scale: TGraphScale, graphHeight: number) => number | undefined;
  getYTicks: (scale: TGraphScale | undefined, graphHeight: number) => { y: number; value: string }[];
  drawGraphTitles: boolean;
  settings: TGraphSettings;
  timeframeWidth: number;
  dimensions: IGraphDimensions;
  selectedTab?: number;
}

export default Graphs;
