import * as React from 'react';
import { useIntl } from 'react-intl';
import { useAppSelector as useSelector } from 'Store/index';
import { filterNotSeizureDocs } from '../utils';
import { formatPartialDate, sortPartialDate } from 'neuro-utils';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';

const SeizuresDash = (props: IDashProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const { documents } = props;

  const sortedAndMergedDocs = useSelector((state: IState) => state.myapp.sortedAndMergedDocuments);
  const patientReportedSeizureDocs: Array<IPatientReportedSeizure & IControlProps> =
    sortedAndMergedDocs?.filter((d) => d._type === 'patientReportedSeizure') || [];

  const unhandledPatRepSeizDocs = patientReportedSeizureDocs.filter((d) => !d?.status);
  const unhandledPatRepSeizAmount = unhandledPatRepSeizDocs.length;
  const latestUnhandledPatRepSeiz = unhandledPatRepSeizDocs.sort((a, b) =>
    sortPartialDate(b.startDate, a.startDate),
  )[0];

  const acceptedPatRepSeizDocs = patientReportedSeizureDocs.filter((d) => d?.status === 'accepted');

  const docsSorted = [...filterNotSeizureDocs(documents), ...acceptedPatRepSeizDocs].sort((a, b) =>
    sortPartialDate(
      'date' in b ? b.date : 'startDate' in b ? b.startDate : undefined,
      'date' in a ? a.date : 'startDate' in a ? a.startDate : undefined,
    ),
  );

  // latest seizure free period
  const seizureFreePeriods = documents.filter((d) => d._type === 'seizureFreePeriod') as ISeizureFreePeriod[];
  if (seizureFreePeriods.every((d) => d.startDate))
    seizureFreePeriods.sort((a, b) => sortPartialDate(b.startDate, a.startDate))[0];
  const latestPeriod = seizureFreePeriods.length > 0 ? seizureFreePeriods[0] : undefined;

  return (
    <TileContentMaker
      type="specificListNoDate"
      data={[
        {
          title: fm('seizure.latestAccepted'),
          value:
            docsSorted.length > 0 && 'date' in docsSorted[0]
              ? formatPartialDate(docsSorted[0]?.date)
              : docsSorted.length > 0 && 'startDate' in docsSorted[0]
                ? formatPartialDate(docsSorted[0]?.startDate)
                : '',
          key: 'latestAccepted',
        },
        {
          title: fm('patientReportedSeizure.latestUnhandled'),
          value: formatPartialDate(latestUnhandledPatRepSeiz?.startDate),
          key: 'latestUnhandled',
        },
        {
          title: fm('patientReportedSeizure.amountOfUnhandled'),
          value: unhandledPatRepSeizAmount,
          key: 'amountOfUnhandled',
        },

        {
          title: fm('seizure.seizureFreePeriod'),
          value:
            latestPeriod?.startDate || latestPeriod?.endDate ? (
              <>
                <div>{formatPartialDate(latestPeriod?.startDate)} –</div>
                <div style={{ textAlign: 'left' }}>{formatPartialDate(latestPeriod?.endDate)}</div>
              </>
            ) : undefined,
          key: 'latestSeizureFreePeriod',
        },
      ]}
    />
  );
};

interface IDashProps {
  documents: Array<ISeizureDoc>;
}

export default SeizuresDash;
